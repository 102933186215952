import { graphql } from 'gatsby'
import React from 'react'

import { Layout } from '../components/layouts/Layout'
import {
  CompanyHero,
  CompanyNews,
  GetInTouch,
  Locations,
  SEO,
  Team,
} from '../components/organisms'

const CompanyPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.contentstackCompanyPage.title}
      description={data.contentstackCompanyPage.seo_description}
    />

    <CompanyHero />
    <Team />
    <Locations />
    <CompanyNews />
    <GetInTouch />
  </Layout>
)

export default CompanyPage

export const query = graphql`
  query {
    contentstackCompanyPage {
      title
      seo_description
    }
  }
`
